import listStyles from "@content-sections/formatted-text/list-styles.module.css";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import styles from "./RichTextFormatter.module.css";

export const RichTextFormatter: FC<{
  content: string;
}> = ({ content }) => {
  const changeHeaders = content
    ? content.replaceAll("<h1", "<h2").replaceAll("</h1>", "</h2>")
    : "";
  return (
    <div
      className={cn(listStyles.root, styles.base, "links-container")}
      dangerouslySetInnerHTML={{ __html: changeHeaders }}
    />
  );
};
